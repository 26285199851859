import {
  Audiotrack,
  BrandingWatermark,
  Category,
  Home,
  LibraryMusic,
  Person,
  Sports,
  Subscriptions,
  TheaterComedy,
  VideoCall,
} from '@mui/icons-material';
import { Divider, Grid, List, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SidebarLink from './SidebarLink';

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div>
        <Toolbar variant="dense" />
        <Divider />
        <List sx={{ p: 1 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item>
              <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">
                COLLECTION TYPES
              </Typography>
              <SidebarLink
                to="/artists"
                label="Artist"
                active={location.pathname.startsWith('/artists')}
                icon={<Person />}
              />
              <SidebarLink
                to="/homes"
                label="Home Items"
                active={location.pathname.startsWith('/homes')}
                icon={<Home />}
              />
              <SidebarLink
                to="/releases"
                label="Release"
                active={location.pathname.startsWith('/releases')}
                icon={<LibraryMusic />}
              />
              <SidebarLink
                to="/videos"
                label="Video"
                active={location.pathname.startsWith('/videos')}
                icon={<VideoCall />}
              />
              <SidebarLink
                to="/video-categories"
                label="Video Category"
                active={location.pathname.startsWith('/video-categories')}
                icon={<Category />}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                FORMS
              </Typography>
              <SidebarLink
                to="/brand-partnerships"
                label="Brand Partnership"
                active={location.pathname.startsWith('/brand-partnerships')}
                icon={<BrandingWatermark />}
              />
              <SidebarLink
                to="/music-syncs"
                label="Music Sync"
                active={location.pathname.startsWith('/music-syncs')}
                icon={<Audiotrack />}
              />
              <SidebarLink
                to="/performance-appearances"
                label="Performance"
                active={location.pathname.startsWith(
                  '/performance-appearances'
                )}
                icon={<TheaterComedy />}
              />
              <SidebarLink
                to="/subscriptions"
                label="Subscription"
                active={location.pathname.startsWith('/subscriptions')}
                icon={<Subscriptions />}
              />
              <SidebarLink
                to="/talents"
                label="Talent"
                active={location.pathname.startsWith('/talents')}
                icon={<Sports />}
              />
            </Grid>
          </Grid>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
