import { Add, DeleteForever } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Confirmation from '../../../core/ui/dialog/Confirmation';
import { Artist } from '../../artist/artist';
import {
  useAddFeaturing,
  useDeleteFeaturing,
} from '../hooks/useReleaseController';
import { Featuring } from '../release';

const FeaturingList = ({
  featuringList,
  releaseId,
  newArtistList,
}: {
  featuringList: Featuring[];
  releaseId: string;
  newArtistList: Artist[];
}) => {
  const [curFeaturing, setCurFeaturing] = useState<Artist | null>(null);
  const { loading, deleteFeaturing } = useDeleteFeaturing(releaseId);
  const { loading: adding, addFeaturing } = useAddFeaturing(releaseId);

  return (
    <>
      <Grid container display="flex" flexDirection="column">
        {featuringList.map((featuring) => (
          <Paper
            key={`featuring-${featuring.id}`}
            sx={{
              maxWidth: '700px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              mb: 1,
              px: 2,
              py: '4px',
              alignItems: 'center',
            }}
          >
            <Typography>{featuring.artist.fullName}</Typography>

            <Grid display="flex" alignItems="center">
              <Confirmation
                title="Remove featuring"
                message="Are you sure you want to remove featured artist?"
                onConfirm={() => deleteFeaturing(featuring.id)}
                buttonEnabled={!loading}
              >
                <IconButton sx={{ ml: 2 }}>
                  <DeleteForever color="error" />
                </IconButton>
              </Confirmation>
            </Grid>
          </Paper>
        ))}

        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
            <FormControl sx={{ minWidth: 120 }} fullWidth>
              <InputLabel>Add Featured Artist</InputLabel>
              <Select
                value={curFeaturing?.id || ''}
                size="small"
                label="Add Featured Artist"
                onChange={(e) => {
                  setCurFeaturing(
                    newArtistList.find((i) => i.id === e.target.value) || null
                  );
                }}
              >
                {newArtistList.map((artist) => (
                  <MenuItem key={artist.id} value={artist.id}>
                    {artist.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item alignSelf="flex-start" sx={{}}>
            <IconButton
              disabled={adding}
              onClick={() => {
                if (curFeaturing !== null) {
                  addFeaturing(curFeaturing.id);
                }
              }}
            >
              <Add color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FeaturingList;
