import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import { ArtistEdit, Artist } from '../artist';
import EditArtistForm from '../components/EditArtistForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';
import useFetchArtistReleases from '../hooks/useFetchArtistReleases';
import ArtistReleaseList from '../components/ArtistReleaseList';

const EditArtistContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [artist, setArtist] = useState<Artist | null>(null);

  const { releases } = useFetchArtistReleases(id!);

  const fetchArtist = () =>
    fetchData(`artists/${id}`, {
      onSuccess: (data: Artist) => {
        setArtist(data);
      },
    });

  useEffect(() => {
    fetchArtist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: ArtistEdit,
    { setFieldError }: FormikHelpers<ArtistEdit>
  ) => {
    const formData: any = parseFormQuery(values, []);
    formData.append('featuredOnBanner', values.featuredOnBanner ? 1 : 0);

    await callApi({
      endpoint: `auth-bana29/artists/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchArtist();
        toastMessage('Artist Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/artists" />}>
      <Loader loading={loading || !artist}>
        <Grid container display="flex">
          <Grid item sx={{ p: 2 }}>
            <Grid container sx={{ mb: 1, px: 1 }}>
              <Typography variant="h5">Edit Artist</Typography>
            </Grid>
            <EditArtistForm
              artist={artist!}
              onSubmit={handleSubmit}
              submitting={submitting}
            />
          </Grid>

          <Grid item sx={{ p: 2 }}>
            <Grid container sx={{ mb: 1, px: 1 }}>
              <Typography variant="h5">Releases</Typography>
            </Grid>
            <ArtistReleaseList id={id!} releases={releases} />
          </Grid>
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditArtistContainer;
