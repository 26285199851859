import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import { MusicSyncEdit, MusicSync } from '../musicSync';
import EditMusicSyncForm from '../components/EditMusicSyncForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';

const EditMusicSyncContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [musicSync, setMusicSync] = useState<MusicSync | null>(null);

  const fetchMusicSync = () =>
    fetchData(`auth-bana29/music-syncs/${id}`, {
      onSuccess: (data: MusicSync) => {
        setMusicSync(data);
      },
    });

  useEffect(() => {
    fetchMusicSync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: MusicSyncEdit,
    { setFieldError }: FormikHelpers<MusicSyncEdit>
  ) => {
    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: `auth-bana29/music-syncs/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchMusicSync();
        toastMessage('MusicSync Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/music-syncs" />}>
      <Loader loading={loading || !musicSync}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">View Music Sync</Typography>
          </Grid>
          <EditMusicSyncForm
            musicSync={musicSync!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditMusicSyncContainer;
