import { useNavigate } from 'react-router-dom';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';

export const useSetFeatured = (artistId: string) => {
  const { callApi, loading } = useSendApiData();
  const navigate = useNavigate();

  const setFeatured = async (releaseId: number) => {
    let success = false;
    await callApi({
      endpoint: `auth-bana29/releases/featured/${releaseId}`,
      data: {},
      onSuccess: () => {
        success = true;
        toastMessage('Release set as featured');
      },
      onError: toastError,
    });

    if (success) {
      navigate('/');
      navigate(`/artists/edit/${artistId}`);
    }
  };

  return { loading, setFeatured };
};

export const useUnSetFeatured = (artistId: string) => {
  const { callApi, loading } = useSendApiData();
  const navigate = useNavigate();

  const unSetFeatured = async (releaseId: number) => {
    let success = false;
    await callApi({
      endpoint: `auth-bana29/releases/un-featured/${releaseId}`,
      data: {},
      onSuccess: () => {
        success = true;
        toastMessage('Release removed from featured');
      },
      onError: toastError,
    });

    if (success) {
      navigate('/');
      navigate(`/artists/edit/${artistId}`);
    }
  };

  return { loading, unSetFeatured };
};
