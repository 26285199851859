import { Box } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { MusicSync } from '../musicSync';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';

const tableHeaders: TableHeader[] = [
  { field: 'firstName', label: 'First Name', align: 'left' },
  { field: 'lastName', label: 'Last Name', align: 'left' },
  { field: 'email', label: 'Email', align: 'left' },
  { field: 'company', label: 'Company', align: 'left' },
  { field: 'subject', label: 'Subject', align: 'left' },
];
const MusicSyncList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`auth-bana29/music-syncs${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<MusicSync>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-bana29/music-syncs/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Item Removed');
        success = true;
      },
      onError: () => {
        if (showMessage)
          toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2, maxWidth: 1200 }}>
          <TableContainer
            modelToken="music-syncs"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Music Syncs"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            routes={{
              edit: '/music-syncs/edit',
              create: '/music-syncs/create',
              delete: '/music-syncs',
              view: '/music-syncs',
            }}
            settings={{
              canCreate: false,
              canEdit: true,
              canDelete: true,
              canViewItem: false,
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default MusicSyncList;
