import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ReleaseCreate } from '../release';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Paper,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import useFetchArtists from '../hooks/useFetchArtists';
import { useMemo, useState } from 'react';
import { Artist } from '../../artist/artist';

type ReleaseProps = {
  onSubmit: (
    value: ReleaseCreate,
    helpers: FormikHelpers<ReleaseCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateReleaseForm = ({ onSubmit, submitting }: ReleaseProps) => {
  const [curFeaturing, setCurFeaturing] = useState<Artist | null>();
  const navigate = useNavigate();

  const handleSubmit = async (
    value: ReleaseCreate,
    helpers: FormikHelpers<ReleaseCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/releases/create', { replace: true });
    }
  };

  const { artists } = useFetchArtists();

  const initialValues: ReleaseCreate = {
    title: '',
    main_artist_id: '',
    featuring_ids: [],
    youtubeLink: '',
    spotifyLink: '',
    appleMusicLink: '',
    youtubeMusicLink: '',
    amazonMusicLink: '',
    pandoraLink: '',
    category: '',
    featuredOnBanner: false,
    bannerImg: null,
    base64Img: '',
    cardImg: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const nonSelectedArtists = useMemo(
    () =>
      artists.filter(
        (i) =>
          +formik.values.main_artist_id !== i.id &&
          !formik.values.featuring_ids.map((i) => i.id).includes(i.id)
      ),
    [artists, formik.values.main_artist_id, formik.values.featuring_ids]
  );

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      fullWidth
                      error={Boolean(formik.errors.main_artist_id)}
                    >
                      <InputLabel>Main Artist</InputLabel>
                      <Select
                        value={formik.values.main_artist_id}
                        size="small"
                        label="Main Artist"
                        onChange={formik.handleChange}
                        name="main_artist_id"
                      >
                        {artists.map((artist) => (
                          <MenuItem key={artist.id} value={artist.id}>
                            {artist.fullName}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(formik.errors.main_artist_id) && (
                        <FormHelperText>
                          {formik.errors.main_artist_id}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Box sx={{ border: '1px solid lightgrey', p: 1, mt: 2 }}>
                  <Grid container spacing={1} sx={{}}>
                    <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                      <Typography variant="h6">Featuring Artists</Typography>
                    </Grid>
                  </Grid>

                  {formik.values.featuring_ids.map((artist, index) => (
                    <Grid
                      key={`art-${artist.id}`}
                      container
                      spacing={1}
                      sx={{ mt: '4px' }}
                    >
                      <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                        <Typography>{artist.fullName}</Typography>
                      </Grid>

                      <Grid item alignSelf="flex-start" sx={{}}>
                        <IconButton
                          onClick={() => {
                            const curArtists = [...formik.values.featuring_ids];
                            curArtists.splice(index, 1);
                            formik.setFieldValue('featuring_ids', curArtists);
                          }}
                        >
                          <Remove color="primary" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid container spacing={1} sx={{ mt: '4px' }}>
                    <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                      <FormControl sx={{ minWidth: 120 }} fullWidth>
                        <InputLabel>Featured Artist</InputLabel>
                        <Select
                          value={curFeaturing?.id || ''}
                          size="small"
                          label="Featured Artist"
                          onChange={(e) => {
                            setCurFeaturing(
                              artists.find((i) => i.id === e.target.value) ||
                                null
                            );
                          }}
                        >
                          {nonSelectedArtists.map((artist) => (
                            <MenuItem key={artist.id} value={artist.id}>
                              {artist.fullName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item alignSelf="flex-start" sx={{}}>
                      <IconButton
                        onClick={() => {
                          formik.setFieldValue('featuring_ids', [
                            ...formik.values.featuring_ids,
                            curFeaturing,
                          ]);
                          setCurFeaturing(null);
                        }}
                      >
                        <Add color="primary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="youtubeLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Youtube Link"
                      placeholder=""
                      value={formik.values.youtubeLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.youtubeLink)}
                      helperText={formik.errors.youtubeLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="spotifyLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Spotify Link"
                      placeholder=""
                      value={formik.values.spotifyLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.spotifyLink)}
                      helperText={formik.errors.spotifyLink}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="appleMusicLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Apple Music Link"
                      placeholder=""
                      value={formik.values.appleMusicLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.appleMusicLink)}
                      helperText={formik.errors.appleMusicLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="youtubeMusicLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Youtube Music Link"
                      placeholder=""
                      value={formik.values.youtubeMusicLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.youtubeMusicLink)}
                      helperText={formik.errors.youtubeMusicLink}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="amazonMusicLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Amazon Music Link"
                      placeholder=""
                      value={formik.values.amazonMusicLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.amazonMusicLink)}
                      helperText={formik.errors.amazonMusicLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="pandoraLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Pandora Link"
                      placeholder=""
                      value={formik.values.pandoraLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.pandoraLink)}
                      helperText={formik.errors.pandoraLink}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      fullWidth
                      error={Boolean(formik.errors.category)}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={formik.values.category}
                        size="small"
                        label="Category"
                        onChange={formik.handleChange}
                        name="category"
                      >
                        <MenuItem value="SINGLES">Single</MenuItem>
                        <MenuItem value="ALBUMS">Album</MenuItem>
                        <MenuItem value="EPS">Ep</MenuItem>
                      </Select>
                      {Boolean(formik.errors.category) && (
                        <FormHelperText>
                          {formik.errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl error>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="featuredOnBanner"
                            checked={formik.values.featuredOnBanner}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Featured on banner"
                      />
                      {Boolean(formik.errors.featuredOnBanner) && (
                        <FormHelperText>
                          {formik.errors.featuredOnBanner}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Banner Image
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'bannerImg',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="bannerImg"
                      />
                    </Box>
                    {formik.touched.bannerImg && formik.errors.bannerImg && (
                      <FormHelperText error>
                        {formik.errors.bannerImg}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="base64Img"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Base64 Image"
                      placeholder=""
                      value={formik.values.base64Img}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.base64Img)}
                      helperText={formik.errors.base64Img}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Card Image
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'cardImg',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="cardImg"
                      />
                    </Box>
                    {formik.touched.cardImg && formik.errors.cardImg && (
                      <FormHelperText error>
                        {formik.errors.cardImg}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateReleaseForm;
