
import { FormikHelpers, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { VideoCategoryCreate } from "../videoCategory";
import { Grid, TextField, Box, FormHelperText, Paper, Button } from "@mui/material";


type VideoCategoryProps = {
  onSubmit: (
    value: VideoCategoryCreate,
    helpers: FormikHelpers<VideoCategoryCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  
};

const CreateVideoCategoryForm = ({
  onSubmit,
  submitting,
  
}: VideoCategoryProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: VideoCategoryCreate,
    helpers: FormikHelpers<VideoCategoryCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/video-categories/create', { replace: true });
    };
  };

  const initialValues: VideoCategoryCreate =  {    title: '',
    bannerImg: null,
    base64Img: '',
    cardImg: null,
}

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    
<>
  <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
      
<Grid item>
  <Paper sx={{ p: 2, pb: 3 }}>
  <Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="title"
    size="small"
    type="text"
    variant="outlined"
    label="Title"
    placeholder=""
    value={formik.values.title}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.title)}
    helperText={formik.errors.title}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <Grid item xs={12}>
    Banner Image
  </Grid>
  <Box>
    <input
      type="file"
      onChange={({ target: { files } }) => {
        formik.setFieldValue(
          'bannerImg',
          files && files.length ? files[0] : null
        );
      }}
      name="bannerImg"
    />
  </Box>
  {formik.touched.bannerImg && formik.errors.bannerImg && (
    <FormHelperText error>
      {formik.errors.bannerImg}
    </FormHelperText>
  )}
</Grid>

<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="base64Img"
    size="small"
    type="text"
    variant="outlined"
    label="Base64 Image"
    placeholder=""
    value={formik.values.base64Img}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.base64Img)}
    helperText={formik.errors.base64Img}
  />
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} sx={{ mt: 2 }}>
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <Grid item xs={12}>
    Card Image
  </Grid>
  <Box>
    <input
      type="file"
      onChange={({ target: { files } }) => {
        formik.setFieldValue(
          'cardImg',
          files && files.length ? files[0] : null
        );
      }}
      name="cardImg"
    />
  </Box>
  {formik.touched.cardImg && formik.errors.cardImg && (
    <FormHelperText error>
      {formik.errors.cardImg}
    </FormHelperText>
  )}
</Grid>
</Grid>

    <Grid container sx={{ mt: 2 }}>
      <Button
        type="submit"
        variant="contained"
        disabled={submitting}
        size="small"
      >
        {submitting ? 'Saving' : 'Save'}
      </Button>
    </Grid>
  </Paper>
</Grid>

      </Grid>
    </form>
  </Box>
</>
  
  );
};

export default CreateVideoCategoryForm;
