import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { Subscription } from '../subscription';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import constants from '../../../core/utils/constants';

const tableHeaders: TableHeader[] = [
  { field: 'firstName', label: 'First Name', align: 'left' },
  { field: 'lastName', label: 'Last Name', align: 'left' },
  { field: 'email', label: 'Email', align: 'left' },
  { field: 'country', label: 'Country', align: 'left' },
];
const SubscriptionList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`auth-bana29/subscriptions${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Subscription>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `auth-bana29/subscriptions/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Item Removed');
        success = true;
      },
      onError: () => {
        if (showMessage)
          toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2, maxWidth: 1200 }}>
          <Grid sx={{ mb: 2 }}>
            <a
              href={`${constants.apiUrl}/subscriptions/csv`}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="outlined">Export CSV</Button>
            </a>
          </Grid>
          <TableContainer
            modelToken="subscriptions"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Subscriptions"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            routes={{
              edit: '/subscriptions/edit',
              create: '/subscriptions/create',
              delete: '/subscriptions',
              view: '/subscriptions',
            }}
            settings={{
              canCreate: false,
              canEdit: false,
              canDelete: true,
              canViewItem: false,
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default SubscriptionList;
