import { FormikHelpers, useFormik } from 'formik';
import { ArtistEdit, Artist } from '../artist';
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Box,
  Paper,
  Button,
} from '@mui/material';
import { getMediaUrl } from '../../../core/utils/image';

type ArtistProps = {
  onSubmit: (
    value: ArtistEdit,
    helpers: FormikHelpers<ArtistEdit>
  ) => Promise<void>;
  submitting: boolean;
  artist: Artist;
};

const EditArtistForm = ({ onSubmit, submitting, artist }: ArtistProps) => {
  const handleSubmit = async (
    value: ArtistEdit,
    helpers: FormikHelpers<ArtistEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: ArtistEdit = {
    fullName: artist.fullName,
    facebookLink: artist.facebookLink,
    twitterLink: artist.twitterLink,
    youtubeLink: artist.youtubeLink,
    instagramLink: artist.instagramLink,
    telegramLink: artist.telegramLink,
    tiktokLink: artist.tiktokLink,
    spotifyLink: artist.spotifyLink,
    biography: artist.biography,
    genre: artist.genre,
    featuredOnBanner: !!artist.featuredOnBanner,
    bannerImg: null,
    base64Img: artist.base64Img,
    cardImg: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="fullName"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="FullName"
                      placeholder=""
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.fullName)}
                      helperText={formik.errors.fullName}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="facebookLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Facebook Link"
                      placeholder=""
                      value={formik.values.facebookLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.facebookLink)}
                      helperText={formik.errors.facebookLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="twitterLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Twitter Link"
                      placeholder=""
                      value={formik.values.twitterLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.twitterLink)}
                      helperText={formik.errors.twitterLink}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="youtubeLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Youtube Link"
                      placeholder=""
                      value={formik.values.youtubeLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.youtubeLink)}
                      helperText={formik.errors.youtubeLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="instagramLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Instagram Link"
                      placeholder=""
                      value={formik.values.instagramLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.instagramLink)}
                      helperText={formik.errors.instagramLink}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="telegramLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Telegram Link"
                      placeholder=""
                      value={formik.values.telegramLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.telegramLink)}
                      helperText={formik.errors.telegramLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="tiktokLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Tiktok Link"
                      placeholder=""
                      value={formik.values.tiktokLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.tiktokLink)}
                      helperText={formik.errors.tiktokLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="spotifyLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Spotify Link"
                      placeholder=""
                      value={formik.values.spotifyLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.spotifyLink)}
                      helperText={formik.errors.spotifyLink}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="biography"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Biography"
                      placeholder=""
                      value={formik.values.biography}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.biography)}
                      helperText={formik.errors.biography}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="genre"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Genre"
                      placeholder=""
                      value={formik.values.genre}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.genre)}
                      helperText={formik.errors.genre}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl error>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="featuredOnBanner"
                            checked={formik.values.featuredOnBanner}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Featured on banner"
                      />
                      {Boolean(formik.errors.featuredOnBanner) && (
                        <FormHelperText>
                          {formik.errors.featuredOnBanner}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Banner Image
                    </Grid>
                    <Grid item sx={{ mr: 3 }}>
                      <img
                        src={getMediaUrl(
                          artist.media.length
                            ? artist.media.filter(
                                (i) => i.collection_name === 'banners'
                              )[0]
                            : null
                        )}
                        width="256px"
                        alt="cover"
                      />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'bannerImg',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="bannerImg"
                      />
                    </Box>
                    {formik.touched.bannerImg && formik.errors.bannerImg && (
                      <FormHelperText error>
                        {formik.errors.bannerImg}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="base64Img"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Base64 Image"
                      placeholder=""
                      value={formik.values.base64Img}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.base64Img)}
                      helperText={formik.errors.base64Img}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Card Image
                    </Grid>
                    <Grid item sx={{ mr: 3 }}>
                      <img
                        src={getMediaUrl(
                          artist.media.length
                            ? artist.media.filter(
                                (i) => i.collection_name === 'cards'
                              )[0]
                            : null
                        )}
                        width="256px"
                        alt="cover"
                      />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'cardImg',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="cardImg"
                      />
                    </Box>
                    {formik.touched.cardImg && formik.errors.cardImg && (
                      <FormHelperText error>
                        {formik.errors.cardImg}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditArtistForm;
