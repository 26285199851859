/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { Artist } from '../../artist/artist';

const useFetchArtists = () => {
  const { fetchData, data: artists, loading } = useFetchApiData<Artist[]>([]);

  useEffect(() => {
    fetchData('artists');
  }, []);

  return { artists, loading };
};

export default useFetchArtists;
