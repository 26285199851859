import { FormikHelpers, useFormik } from 'formik';
import { MusicSyncEdit, MusicSync } from '../musicSync';
import { Grid, TextField, Paper, Box } from '@mui/material';

type MusicSyncProps = {
  onSubmit: (
    value: MusicSyncEdit,
    helpers: FormikHelpers<MusicSyncEdit>
  ) => Promise<void>;
  submitting: boolean;
  musicSync: MusicSync;
};

const EditMusicSyncForm = ({
  onSubmit,
  submitting,
  musicSync,
}: MusicSyncProps) => {
  const handleSubmit = async (
    value: MusicSyncEdit,
    helpers: FormikHelpers<MusicSyncEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: MusicSyncEdit = {
    firstName: musicSync.firstName,
    lastName: musicSync.lastName,
    email: musicSync.email,
    company: musicSync.company,
    subject: musicSync.subject,
    message: musicSync.message,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="firstName"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="First Name"
                      placeholder=""
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.firstName)}
                      helperText={formik.errors.firstName}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="lastName"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Last Name"
                      placeholder=""
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.lastName)}
                      helperText={formik.errors.lastName}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="email"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Email"
                      placeholder=""
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.email)}
                      helperText={formik.errors.email}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="company"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Company"
                      placeholder=""
                      value={formik.values.company}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.company)}
                      helperText={formik.errors.company}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="subject"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Subject"
                      placeholder=""
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.subject)}
                      helperText={formik.errors.subject}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="message"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Message"
                      placeholder=""
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.message)}
                      helperText={formik.errors.message}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditMusicSyncForm;
