import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { ReleaseCreate } from '../release';
import CreateReleaseForm from '../components/CreateReleaseForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';

const CreateReleaseContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: ReleaseCreate,
    { setFieldError }: FormikHelpers<ReleaseCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(
      {
        ...values,
        featuring_ids: values.featuring_ids.map((i) => i.id),
        featuredOnBanner: values.featuredOnBanner ? 1 : 0,
      },
      []
    );

    await callApi({
      endpoint: 'auth-bana29/releases',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Release Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/releases" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Release</Typography>
        </Grid>
        <CreateReleaseForm onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
    </Layout>
  );
};

export default CreateReleaseContainer;
