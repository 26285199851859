import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../core/utils/validation";
import useFetchApiData from "../../../core/hooks/useFetchApiData";
import Loader from "../../../core/ui/utility/Loader";
import { VideoCategoryEdit, VideoCategory } from "../videoCategory";
import EditVideoCategoryForm from "../components/EditVideoCategoryForm";
import ReturnButton from "../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../core/utils/utility";

const EditVideoCategoryContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [videoCategory, setVideoCategory] = useState<VideoCategory | null>(null);

  const fetchVideoCategory = () =>
    fetchData(`video-categories/${id}`, {
      onSuccess: (data: VideoCategory) => {
        setVideoCategory(data);
      },
    });

  useEffect(() => {
    fetchVideoCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: VideoCategoryEdit,
    { setFieldError }: FormikHelpers<VideoCategoryEdit>
  ) => {
    const formData: any = parseFormQuery(values, [])

    await callApi({
      endpoint: `auth-bana29/video-categories/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchVideoCategory();
        toastMessage('VideoCategory Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/video-categories" />}>
      <Loader loading={loading || !videoCategory}>
        <Grid sx={{ p: 2 }}>  
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit VideoCategory</Typography>
          </Grid>
          <EditVideoCategoryForm videoCategory={videoCategory!} onSubmit={handleSubmit} submitting={submitting} />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditVideoCategoryContainer;
