import { FormikHelpers, useFormik } from 'formik';
import { VideoEdit, Video } from '../video';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Paper,
  Box,
  Button,
} from '@mui/material';
import useFetchCategories from '../hooks/useFetchVideos';

type VideoProps = {
  onSubmit: (
    value: VideoEdit,
    helpers: FormikHelpers<VideoEdit>
  ) => Promise<void>;
  submitting: boolean;
  video: Video;
};

const EditVideoForm = ({ onSubmit, submitting, video }: VideoProps) => {
  const handleSubmit = async (
    value: VideoEdit,
    helpers: FormikHelpers<VideoEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: VideoEdit = {
    title: video.title,
    artistName: video.artistName,
    videoId: video.videoId,
    video_category_id: video.video_category_id,
    youtubeImgUrl: video.youtubeImgUrl,
    youtubeLink: video.youtubeLink,
  };

  const { categories } = useFetchCategories();
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="artistName"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Artist Name"
                      placeholder=""
                      value={formik.values.artistName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.artistName)}
                      helperText={formik.errors.artistName}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="videoId"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Video Id"
                      placeholder=""
                      value={formik.values.videoId}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.videoId)}
                      helperText={formik.errors.videoId}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      fullWidth
                      error={Boolean(formik.errors.video_category_id)}
                    >
                      <InputLabel>Video Category</InputLabel>
                      <Select
                        value={formik.values.video_category_id}
                        size="small"
                        label="Video Category"
                        onChange={formik.handleChange}
                        name="video_category_id"
                      >
                        {categories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.title}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(formik.errors.video_category_id) && (
                        <FormHelperText>
                          {formik.errors.video_category_id}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="youtubeImgUrl"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Youtube Image Url"
                      placeholder=""
                      value={formik.values.youtubeImgUrl}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.youtubeImgUrl)}
                      helperText={formik.errors.youtubeImgUrl}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="youtubeLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Youtube LInk"
                      placeholder=""
                      value={formik.values.youtubeLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.youtubeLink)}
                      helperText={formik.errors.youtubeLink}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditVideoForm;
