import { FormikHelpers, useFormik } from 'formik';
import { ReleaseEdit, Release } from '../release';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Paper,
  Button,
} from '@mui/material';
import { getMediaUrl } from '../../../core/utils/image';
import { Artist } from '../../artist/artist';

type ReleaseProps = {
  onSubmit: (
    value: ReleaseEdit,
    helpers: FormikHelpers<ReleaseEdit>
  ) => Promise<void>;
  submitting: boolean;
  release: Release;
  artists: Artist[];
};

const EditReleaseForm = ({
  onSubmit,
  submitting,
  release,
  artists,
}: ReleaseProps) => {
  const handleSubmit = async (
    value: ReleaseEdit,
    helpers: FormikHelpers<ReleaseEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: ReleaseEdit = {
    title: release.title,
    main_artist_id: `${release.main_artist_id}`,
    youtubeLink: release.youtubeLink,
    spotifyLink: release.spotifyLink,
    appleMusicLink: release.appleMusicLink,
    youtubeMusicLink: release.youtubeMusicLink,
    amazonMusicLink: release.amazonMusicLink,
    pandoraLink: release.pandoraLink,
    category: release.category,
    featuredOnBanner: !!release.featuredOnBanner,
    bannerImg: null,
    base64Img: release.base64Img,
    cardImg: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      fullWidth
                      error={Boolean(formik.errors.main_artist_id)}
                    >
                      <InputLabel>Main Artist</InputLabel>
                      <Select
                        value={formik.values.main_artist_id}
                        size="small"
                        label="Main Artist"
                        onChange={formik.handleChange}
                        name="main_artist_id"
                      >
                        {artists.map((artist) => (
                          <MenuItem key={artist.id} value={artist.id}>
                            {artist.fullName}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(formik.errors.main_artist_id) && (
                        <FormHelperText>
                          {formik.errors.main_artist_id}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="youtubeLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Youtube Link"
                      placeholder=""
                      value={formik.values.youtubeLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.youtubeLink)}
                      helperText={formik.errors.youtubeLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="spotifyLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Spotify Link"
                      placeholder=""
                      value={formik.values.spotifyLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.spotifyLink)}
                      helperText={formik.errors.spotifyLink}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="appleMusicLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Apple Music Link"
                      placeholder=""
                      value={formik.values.appleMusicLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.appleMusicLink)}
                      helperText={formik.errors.appleMusicLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="youtubeMusicLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Youtube Music Link"
                      placeholder=""
                      value={formik.values.youtubeMusicLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.youtubeMusicLink)}
                      helperText={formik.errors.youtubeMusicLink}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="amazonMusicLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Amazon Music Link"
                      placeholder=""
                      value={formik.values.amazonMusicLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.amazonMusicLink)}
                      helperText={formik.errors.amazonMusicLink}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="pandoraLink"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Pandora Link"
                      placeholder=""
                      value={formik.values.pandoraLink}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.pandoraLink)}
                      helperText={formik.errors.pandoraLink}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      fullWidth
                      error={Boolean(formik.errors.category)}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={formik.values.category}
                        size="small"
                        label="Category"
                        onChange={formik.handleChange}
                        name="category"
                      >
                        <MenuItem value="SINGLES">Single</MenuItem>
                        <MenuItem value="ALBUMS">Album</MenuItem>
                        <MenuItem value="EPS">Ep</MenuItem>
                      </Select>
                      {Boolean(formik.errors.category) && (
                        <FormHelperText>
                          {formik.errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl error>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="featuredOnBanner"
                            checked={formik.values.featuredOnBanner}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Featured on banner"
                      />
                      {Boolean(formik.errors.featuredOnBanner) && (
                        <FormHelperText>
                          {formik.errors.featuredOnBanner}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Banner Image
                    </Grid>
                    <Grid item sx={{ mr: 3 }}>
                      <img
                        src={getMediaUrl(
                          release.media.length
                            ? release.media.filter(
                                (i) => i.collection_name === 'banners'
                              )[0]
                            : null
                        )}
                        width="256px"
                        alt="cover"
                      />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'bannerImg',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="bannerImg"
                      />
                    </Box>
                    {formik.touched.bannerImg && formik.errors.bannerImg && (
                      <FormHelperText error>
                        {formik.errors.bannerImg}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="base64Img"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Base64 Image"
                      placeholder=""
                      value={formik.values.base64Img}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.base64Img)}
                      helperText={formik.errors.base64Img}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Card Image
                    </Grid>
                    <Grid item sx={{ mr: 3 }}>
                      <img
                        src={getMediaUrl(
                          release.media.length
                            ? release.media.filter(
                                (i) => i.collection_name === 'cards'
                              )[0]
                            : null
                        )}
                        width="256px"
                        alt="cover"
                      />
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'cardImg',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="cardImg"
                      />
                    </Box>
                    {formik.touched.cardImg && formik.errors.cardImg && (
                      <FormHelperText error>
                        {formik.errors.cardImg}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditReleaseForm;
