/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { VideoCategory } from '../../videoCategory/videoCategory';

const useFetchCategories = () => {
  const {
    fetchData,
    data: categories,
    loading,
  } = useFetchApiData<VideoCategory[]>([]);

  useEffect(() => {
    fetchData('video-categories');
  }, []);

  return { categories, loading };
};

export default useFetchCategories;
