import { FormikHelpers, useFormik } from 'formik';
import { TalentEdit, Talent } from '../talent';
import { Grid, TextField, Paper, Box, Typography } from '@mui/material';

type TalentProps = {
  onSubmit: (
    value: TalentEdit,
    helpers: FormikHelpers<TalentEdit>
  ) => Promise<void>;
  submitting: boolean;
  talent: Talent;
};

const EditTalentForm = ({ onSubmit, submitting, talent }: TalentProps) => {
  const handleSubmit = async (
    value: TalentEdit,
    helpers: FormikHelpers<TalentEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: TalentEdit = {
    firstName: talent.firstName,
    lastName: talent.lastName,
    email: talent.email,
    stageName: talent.stageName,
    country: talent.country,
    city: talent.city,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="firstName"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="First Name"
                      placeholder=""
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.firstName)}
                      helperText={formik.errors.firstName}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="lastName"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Last Name"
                      placeholder=""
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.lastName)}
                      helperText={formik.errors.lastName}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="email"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Email"
                      placeholder=""
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.email)}
                      helperText={formik.errors.email}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="stageName"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Stage Name"
                      placeholder=""
                      value={formik.values.stageName}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.stageName)}
                      helperText={formik.errors.stageName}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="country"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Country"
                      placeholder=""
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.country)}
                      helperText={formik.errors.country}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="city"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="City"
                      placeholder=""
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.city)}
                      helperText={formik.errors.city}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>

                <Typography sx={{ mt: 2 }}>Previous Work Links</Typography>
                {(JSON.parse(talent.previousWorkLinks || '[]') as string[]).map(
                  (link, i) => (
                    <Grid
                      container
                      justifyContent="space-between"
                      spacing={1}
                      sx={{ mt: 1 }}
                      key={`talent-${i}`}
                    >
                      <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                        <Typography>
                          <a href={link} target="_blank" rel="noreferrer">
                            {link}
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                )}

                <Typography sx={{ mt: 2 }}>Social Media Links</Typography>
                {(JSON.parse(talent.socialMediaLinks || '[]') as string[]).map(
                  (link, i) => (
                    <Grid
                      container
                      justifyContent="space-between"
                      spacing={1}
                      sx={{ mt: 1 }}
                      key={`talent-${i}`}
                    >
                      <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                        <Typography>
                          <a href={link} target="_blank" rel="noreferrer">
                            {link}
                          </a>
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                )}
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditTalentForm;
