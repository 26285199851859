import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListHomePage from '../../features/home/pages/ListHomePage';
import CreateHomePage from '../../features/home/pages/CreateHomePage';
import EditHomePage from '../../features/home/pages/EditHomePage';
import ListSubscriptionPage from '../../features/subscription/pages/ListSubscriptionPage';
import ListArtistPage from '../../features/artist/pages/ListArtistPage';
import CreateArtistPage from '../../features/artist/pages/CreateArtistPage';
import EditArtistPage from '../../features/artist/pages/EditArtistPage';
import ListReleasePage from '../../features/release/pages/ListReleasePage';
import CreateReleasePage from '../../features/release/pages/CreateReleasePage';
import EditReleasePage from '../../features/release/pages/EditReleasePage';
import ListVideoCategoryPage from '../../features/videoCategory/pages/ListVideoCategoryPage';
import CreateVideoCategoryPage from '../../features/videoCategory/pages/CreateVideoCategoryPage';
import EditVideoCategoryPage from '../../features/videoCategory/pages/EditVideoCategoryPage';
import ListVideoPage from '../../features/video/pages/ListVideoPage';
import CreateVideoPage from '../../features/video/pages/CreateVideoPage';
import EditVideoPage from '../../features/video/pages/EditVideoPage';
import ListBrandPartnershipPage from '../../features/brandPartnership/pages/ListBrandPartnershipPage';
import EditBrandPartnershipPage from '../../features/brandPartnership/pages/EditBrandPartnershipPage';
import ListMusicSyncPage from '../../features/musicSync/pages/ListMusicSyncPage';
import EditMusicSyncPage from '../../features/musicSync/pages/EditMusicSyncPage';
import ListPerformanceAppearancePage from '../../features/performanceAppearance/pages/ListPerformanceAppearancePage';
import EditPerformanceAppearancePage from '../../features/performanceAppearance/pages/EditPerformanceAppearancePage';
import ListTalentPage from '../../features/talent/pages/ListTalentPage';
import EditTalentPage from '../../features/talent/pages/EditTalentPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        {/* Home  */}
        <Route
          path="/homes"
          element={
            <PrivateRoute>
              <ListHomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/homes/create"
          element={
            <PrivateRoute>
              <CreateHomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/homes/edit/:id"
          element={
            <PrivateRoute>
              <EditHomePage />
            </PrivateRoute>
          }
        />

        {/* Subscriptions  */}
        <Route
          path="/subscriptions"
          element={
            <PrivateRoute>
              <ListSubscriptionPage />
            </PrivateRoute>
          }
        />

        {/* Artist  */}
        <Route
          path="/artists"
          element={
            <PrivateRoute>
              <ListArtistPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/artists/create"
          element={
            <PrivateRoute>
              <CreateArtistPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/artists/edit/:id"
          element={
            <PrivateRoute>
              <EditArtistPage />
            </PrivateRoute>
          }
        />

        {/* Release  */}
        <Route
          path="/releases"
          element={
            <PrivateRoute>
              <ListReleasePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/releases/create"
          element={
            <PrivateRoute>
              <CreateReleasePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/releases/edit/:id"
          element={
            <PrivateRoute>
              <EditReleasePage />
            </PrivateRoute>
          }
        />

        {/* Video Category  */}
        <Route
          path="/video-categories"
          element={
            <PrivateRoute>
              <ListVideoCategoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/video-categories/create"
          element={
            <PrivateRoute>
              <CreateVideoCategoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/video-categories/edit/:id"
          element={
            <PrivateRoute>
              <EditVideoCategoryPage />
            </PrivateRoute>
          }
        />

        {/* Video  */}
        <Route
          path="/videos"
          element={
            <PrivateRoute>
              <ListVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/videos/create"
          element={
            <PrivateRoute>
              <CreateVideoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/videos/edit/:id"
          element={
            <PrivateRoute>
              <EditVideoPage />
            </PrivateRoute>
          }
        />

        {/* Brand  */}
        <Route
          path="/brand-partnerships"
          element={
            <PrivateRoute>
              <ListBrandPartnershipPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/brand-partnerships/edit/:id"
          element={
            <PrivateRoute>
              <EditBrandPartnershipPage />
            </PrivateRoute>
          }
        />

        {/* Music  */}
        <Route
          path="/music-syncs"
          element={
            <PrivateRoute>
              <ListMusicSyncPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/music-syncs/edit/:id"
          element={
            <PrivateRoute>
              <EditMusicSyncPage />
            </PrivateRoute>
          }
        />

        {/* Performance  */}
        <Route
          path="/performance-appearances"
          element={
            <PrivateRoute>
              <ListPerformanceAppearancePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/performance-appearances/edit/:id"
          element={
            <PrivateRoute>
              <EditPerformanceAppearancePage />
            </PrivateRoute>
          }
        />

        {/* Talent  */}
        <Route
          path="/talents"
          element={
            <PrivateRoute>
              <ListTalentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/talents/edit/:id"
          element={
            <PrivateRoute>
              <EditTalentPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<>Not Found page</>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
