/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { Release } from '../../release/release';

const useFetchArtistReleases = (artistId: string) => {
  const { data: releases, fetchData } = useFetchApiData<Release[]>([]);

  useEffect(() => {
    fetchData(`releases?sort_field=title&sort_op=asc&artist_id=${artistId}`);
  }, []);

  return { releases };
};

export default useFetchArtistReleases;
