import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import { TalentEdit, Talent } from '../talent';
import EditTalentForm from '../components/EditTalentForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';

const EditTalentContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [talent, setTalent] = useState<Talent | null>(null);

  const fetchTalent = () =>
    fetchData(`auth-bana29/talents/${id}`, {
      onSuccess: (data: Talent) => {
        setTalent(data);
      },
    });

  useEffect(() => {
    fetchTalent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: TalentEdit,
    { setFieldError }: FormikHelpers<TalentEdit>
  ) => {
    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: `auth-bana29/talents/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchTalent();
        toastMessage('Talent Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/talents" />}>
      <Loader loading={loading || !talent}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">View Talent</Typography>
          </Grid>
          <EditTalentForm
            talent={talent!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditTalentContainer;
