import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import { ReleaseEdit, Release } from '../release';
import EditReleaseForm from '../components/EditReleaseForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';
import FeaturingList from '../components/FeaturingList';
import useFetchArtists from '../hooks/useFetchArtists';

const EditReleaseContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [release, setRelease] = useState<Release | null>(null);

  const { artists } = useFetchArtists();

  const fetchRelease = () =>
    fetchData(`releases/${id}`, {
      onSuccess: (data: Release) => {
        setRelease(data);
      },
    });

  useEffect(() => {
    fetchRelease();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: ReleaseEdit,
    { setFieldError }: FormikHelpers<ReleaseEdit>
  ) => {
    const formData: any = parseFormQuery(values, []);
    formData.append('featuredOnBanner', values.featuredOnBanner ? 1 : 0);

    await callApi({
      endpoint: `auth-bana29/releases/${id}?_method=PATCH`,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchRelease();
        toastMessage('Release Edited');
      },
    });
  };

  const nonSelectedArtists = useMemo(() => {
    return release
      ? artists
          .filter((i) => release!.main_artist_id !== i.id)
          .filter(
            (i) => !release!.featuring.map((i) => i.artist_id).includes(i.id)
          )
      : [];
  }, [artists, release]);

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/releases" />}>
      <Loader loading={loading || !release}>
        <Grid container display="flex">
          <Grid sx={{ p: 2 }}>
            <Grid container sx={{ mb: 1, px: 1 }}>
              <Typography variant="h5">Edit Release</Typography>
            </Grid>
            <EditReleaseForm
              release={release!}
              onSubmit={handleSubmit}
              submitting={submitting}
              artists={artists}
            />
          </Grid>

          <Grid sx={{ p: 2 }}>
            <Grid container sx={{ mb: 1, px: 1 }}>
              <Typography variant="h5">Edit Featuring</Typography>
            </Grid>
            <FeaturingList
              releaseId={id!}
              featuringList={release?.featuring || []}
              newArtistList={nonSelectedArtists}
            />
          </Grid>
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditReleaseContainer;
