import { useNavigate } from 'react-router-dom';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';

export const useDeleteFeaturing = (releaseID: string) => {
  const { loading, callApi } = useSendApiData();
  const navigate = useNavigate();

  const deleteFeaturing = async (featuringId: number) => {
    let success = false;
    await callApi({
      endpoint: `auth-bana29/releases/delete-featuring/${featuringId}`,
      data: {},
      onSuccess: () => {
        success = true;
        toastMessage('Featuring removed');
      },
      onError: toastError,
    });

    if (success) {
      setTimeout(() => {
        navigate('/');
        navigate(`/releases/edit/${releaseID}`);
      }, 100);
    }
  };

  return { loading, deleteFeaturing };
};

export const useAddFeaturing = (releaseId: string) => {
  const { loading, callApi } = useSendApiData();
  const navigate = useNavigate();

  const addFeaturing = async (artistId: number) => {
    let success = false;
    await callApi({
      endpoint: `auth-bana29/releases/add-featuring`,
      data: {
        artist_id: artistId,
        release_id: releaseId,
      },
      onSuccess: () => {
        success = true;
        toastMessage('Featuring added');
      },
      onError: toastError,
      onValidationError: (err) => {
        toastMessage(Object.values(err.errors).join('\n'));
      },
    });

    if (success) {
      navigate('/');
      navigate(`/releases/edit/${releaseId}`);
    }
  };

  return { loading, addFeaturing };
};
