import { Edit } from '@mui/icons-material';
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Release } from '../../release/release';
import { useSetFeatured, useUnSetFeatured } from '../hooks/useArtistController';

const ArtistReleaseList = ({
  releases,
  id,
}: {
  releases: Release[];
  id: string;
}) => {
  const navigate = useNavigate();
  const { setFeatured, loading } = useSetFeatured(id);
  const { unSetFeatured, loading: unSetting } = useUnSetFeatured(id);

  return (
    <>
      <Grid container display="flex" flexDirection="column">
        {releases.map((release) => (
          <Paper
            key={`release-${release.id}`}
            sx={{
              maxWidth: '700px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              mb: 1,
              px: 2,
              py: '4px',
              alignItems: 'center',
              position: 'relative',
              paddingTop: release.isFeatured ? 3 : 0,
            }}
          >
            {release.isFeatured ? (
              <Grid
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  background: '#1d5db1',
                  px: 2,
                  py: '2px',
                  color: 'white',
                  borderBottomRightRadius: '8px',
                }}
              >
                Featured
              </Grid>
            ) : (
              <></>
            )}
            <Typography>{release.title}</Typography>

            <Grid display="flex" alignItems="center">
              {release.isFeatured ? (
                <Button
                  disabled={unSetting}
                  onClick={() => unSetFeatured(release.id)}
                  variant="text"
                  size="small"
                  color="warning"
                  sx={{ ml: 4 }}
                >
                  Remove Featured
                </Button>
              ) : (
                <Button
                  disabled={loading}
                  onClick={() => setFeatured(release.id)}
                  variant="text"
                  size="small"
                  color="success"
                  sx={{ ml: 4 }}
                >
                  Set Featured
                </Button>
              )}
              <IconButton
                disabled={loading}
                sx={{ ml: 2 }}
                onClick={() => navigate(`/releases/edit/${id}`)}
              >
                <Edit />
              </IconButton>
            </Grid>
          </Paper>
        ))}
      </Grid>
    </>
  );
};

export default ArtistReleaseList;
